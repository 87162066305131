import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IdpPlansComponent } from './plans/plans.component';
import { IdpShowPlanComponent } from './show-plan/show-plan.component';
import { IdpCreateActivityComponent } from './create-activity/create-activity.component';
import { IdpCreatePlanComponent } from './create-plan/create-plan.component';
import { IdpShowActivityComponent } from '@/pages/idp/show-activity/show-activity.component';
import { IdpContainerComponent } from './container/idp-container.component';

export enum RoutePaths {
  BASE = 'pdi',
  PLANS = 'planos',
  ACTIVITIES = 'atividades',
  NOVA = 'nova',
  NOVO = 'novo',
}

const routes: Routes = [
  {
    path: RoutePaths.PLANS,
    component: IdpContainerComponent,
    children: [
      {
        path: RoutePaths.NOVO,
        component: IdpCreatePlanComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: IdpShowPlanComponent,
          },
          {
            path: RoutePaths.ACTIVITIES,
            children: [
              {
                path: RoutePaths.NOVA,
                component: IdpCreateActivityComponent,
              },
              {
                path: `:activityId`,
                component: IdpShowActivityComponent,
              },
            ],
          },
        ],
      },
      {
        path: '',
        component: IdpPlansComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IdpRoutingModule {}
