import * as React from 'react';
import {
  Alert,
  Box,
  Grid,
  Stack,
  ToggleButton,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useWizard } from '@/react/components/wizard/state';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { ToggleButtonGroup } from '@/react/components/toggle-buttons';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { useCreatePlan } from '@/react/data/idp';

export const RecommendationTypes = {
  AI_RECOMMENDATION: 'aiRecommendation',
  NO_RECOMMENDATION: 'noRecommendation',
} as const;

export const validationSchema = z.object({
  recommendationType: z
    .string()
    .min(1, { message: 'Selecione uma opção para continuar.' }),
});

export const PlanRecommendationTypeForm: React.ElementType<WizardStepComponent> = ({
  onNext,
  onPrevious,
}) => {
  const { createPlan, isPending } = useCreatePlan();
  const { actions: wizardActions, values } = useWizard();
  const defaultValues = {
    recommendationType: values[CreatePlanWizardSteps.RECOMMENDATION_TYPE] || '',
  };
  const { formState, handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  const { recommendationType } = watch();

  const handleCreatePlan = async () => {
    await createPlan(
      { title: null },
      {
        onSuccess: (plan) => {
          window.location.assign(`/pdi/planos/${plan.id}`);
        },
      }
    );
  };

  const handleChange = (_, value) => {
    if (value !== null) {
      setValue('recommendationType', value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const handleNext = async (data) => {
    if (data.recommendationType === RecommendationTypes.NO_RECOMMENDATION) {
      handleCreatePlan();
      return;
    }

    wizardActions.updateValues({
      [CreatePlanWizardSteps.RECOMMENDATION_TYPE]: data.recommendationType,
    });

    if (onNext) {
      onNext();
    }
  };

  const handlePrevious = () => {
    if (onPrevious) {
      onPrevious();
    }
  };

  return (
    <>
      <Box mt={3}>
        <Typography variant="h6">
          Como você gostaria de criar seu plano?
        </Typography>
      </Box>
      <Box mt={3}>
        <form onSubmit={handleSubmit(handleNext)}>
          <ToggleButtonGroup
            aria-label="recommendation type"
            color="primary"
            exclusive
            fullWidth
            onChange={handleChange}
            size="large"
            value={recommendationType}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ToggleButton value={RecommendationTypes.AI_RECOMMENDATION}>
                  <Stack
                    sx={{
                      textAlign: 'left',
                    }}
                    spacing={0}
                    width="100%"
                  >
                    <Typography variant="body3" fontWeight="bold">
                      Recomendado por Inteligência Artificial
                    </Typography>
                    <Typography variant="body3">
                      Defina seus objetivos e deixe nossa IA criar um plano
                      personalizado para você. Após a recomendação, você pode
                      ajustar e personalizar o plano como desejar.
                    </Typography>
                  </Stack>
                </ToggleButton>
              </Grid>
              <Grid item xs={12}>
                <ToggleButton value={RecommendationTypes.NO_RECOMMENDATION}>
                  <Stack
                    sx={{
                      textAlign: 'left',
                    }}
                    spacing={0}
                    width="100%"
                  >
                    <Typography variant="body3" fontWeight="bold">
                      Sem recomendação
                    </Typography>
                    <Typography variant="body3">
                      Crie seu plano do zero, sem recomendações.
                    </Typography>
                  </Stack>
                </ToggleButton>
              </Grid>
              {formState.isSubmitted &&
                formState.errors['recommendationType'] && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      {formState.errors['recommendationType'].message as string}
                    </Alert>
                  </Grid>
                )}
            </Grid>
          </ToggleButtonGroup>
          <WizardStepActions>
            <LoadingButton
              color="primary"
              onClick={handlePrevious}
              variant="text"
            >
              Voltar
            </LoadingButton>
            <LoadingButton
              color="primary"
              loading={isPending}
              onClick={() => {
                trigger();
              }}
              type="submit"
              variant="contained"
            >
              Próximo
            </LoadingButton>
          </WizardStepActions>
        </form>
      </Box>
    </>
  );
};
