export const environment = {
  env: 'staging',
  production: false,
  BACKEND_URL: __BACKEND_URL__,
  FALCON_URL: __FALCON_URL__,
  LEGACY_BACKEND_URL: __LEGACY_BACKEND_URL__,
  NEW_API_ENDPOINT: __NEW_API_ENDPOINT__,
  FRONTEND_URL: __FRONTEND_URL__,
  registry_limit: 100,
  WHATSAPP_NUMERO: __WHATSAPP_NUMERO__,
  MEDIA_URL: __MEDIA_URL__,
  PURPLE_METRICS_COMPANY_ID: __PURPLE_METRICS_COMPANY_ID__,
};
