import React, { useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { ListPlans } from '@/react/components/idp/home/components/list-plans.component';
import { usePlans } from '@/react/data/idp';
import { PlanStatuses } from '@/react/data/idp/plans';
import { PageContainer, SectionHeader } from '@/react/components/idp/shared';
import { PageSkeleton } from '@/react/components/idp/shared';
import { useSetTitle } from '@/react/hooks/useSetTitle';

import { RecommendationClient } from '@/react/data/recommendation/recommendation-client';
import { useCreatePlan } from '@/react/data/idp';
import { PlanInDraftDialog } from '@/react/components/idp/dialogs';

export const PlansHome = () => {
  useSetTitle('Planos de desenvolvimento');
  const { isFetching: fetchingPlans, plans, plansCount } = usePlans();
  const { createPlan, isPending: isPendingCreatePlan } = useCreatePlan();
  const [openModal, setOpenModal] = React.useState(false);
  const [profileModal, setProfileModal] = React.useState(false);
  const [profile, setProfile] = React.useState(null);

  const disableAction = !!plans[PlanStatuses.DRAFT];
  const pendingAction = isPendingCreatePlan;

  const createNewPlan = async () => {
    if (disableAction) {
      return setOpenModal(true);
    }

    await createPlan(
      { title: null },
      {
        onSuccess: (plan) => {
          window.location.assign(`/pdi/planos/${plan.id}`);
        },
      }
    );
  };

  const testProfileEdit = () => {
    setProfileModal(true);
  };

  const fetchProfile = async () => {
    const _profile = await RecommendationClient.getProfile();
    setProfile(_profile);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        {fetchingPlans ? (
          <PageSkeleton />
        ) : (
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <SectionHeader>
                <Stack spacing={2}>
                  <Typography variant="h5">Plano de desenvolvimento</Typography>
                  <Typography variant="body3">
                    Acelere seu crescimento profissional com um plano de
                    desenvolvimento personalizado para alcançar seus objetivos.
                    Crie o seu plano ou use as recomendações da nossa
                    inteligência artificial. <a href="/#">Veja como funciona</a>
                  </Typography>
                </Stack>
              </SectionHeader>
            </Grid>
            <Grid xs={12} md={3} lg={2} item>
              <LoadingButton
                fullWidth
                loading={pendingAction}
                onClick={createNewPlan}
                variant="contained"
                size="large"
              >
                Novo plano
              </LoadingButton>
            </Grid>
            <Grid xs={12} item>
              {plansCount > 0 && <ListPlans plans={plans} />}
            </Grid>
          </Grid>
        )}
        <PlanInDraftDialog
          onClose={() => setOpenModal(false)}
          open={openModal}
          planInDraft={plans[PlanStatuses.DRAFT]}
        />
      </Box>
    </PageContainer>
  );
};
